import {useEffect, useState} from 'react'
import {Col, FormGroup, Label, Row} from 'reactstrap'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import AppInput from 'components/general/Control/Input'
import {Button} from 'components/general/Control'
import {
    useCallback,
    useDispatch,
    useHistory,
    useParams,
} from 'services/hooks.js'
import {
    useGetGearSetupQuery,
    useSaveGearSetupMutation,
} from 'store/slices/gearSetup'
import {getSuccessSaveMessageData, showAlertMessage} from 'services/utils.js'

const GearSetupDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const isCreateNew = params?.device === 'new'

    const {data: gearSetupData} = useGetGearSetupQuery(
        isCreateNew ? skipToken : params?.device
    )
    const [saveGearSetup, {isSuccess}] = useSaveGearSetupMutation()

    const [updateGearSetup, setUpdateGearSetup] = useState({})

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    const onSave = (ev) => {
        ev?.preventDefault()

        saveGearSetup(updateGearSetup)
            .unwrap()
            .catch((r) => onError(r?.data?.message))
    }

    useEffect(() => {
        if (gearSetupData) {
            const gearSteps = gearSetupData?.stepsData || []
            setUpdateGearSetup({...gearSetupData, stepsData: gearSteps})
        }
    }, [gearSetupData])

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updateGearSetup?.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updateGearSetup])

    const setter = (field, value) => {
        setUpdateGearSetup((old) => ({...old, [field]: value}))
    }

    return (
        <div className="px-3">
            <Row className="border border-default rounded-lg py-2">
                <Col sm="12" md="6">
                    <FormGroup>
                        <Label for="deviceId">DEVICE ID</Label>
                        <AppInput
                            name="deviceId"
                            value={updateGearSetup?.deviceId}
                            setter={(v) => setter('deviceId', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label for="name">NAME</Label>
                        <AppInput
                            name="name"
                            value={updateGearSetup?.name}
                            setter={(v) => setter('name', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>IMAGE URL</Label>
                        <AppInput
                            name="image"
                            value={updateGearSetup?.image}
                            setter={(v) => setter('image', v)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            {/* SUBMIT */}

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="button"
                    onClick={onSave}>
                    {isCreateNew ? 'CREATE' : 'UPDATE'} GEAR SETUP
                </Button>
            </Row>
        </div>
    )
}
export default GearSetupDetail

import {Language} from 'components/general/Display'
import {Button, FormGroup, Row} from 'reactstrap'
import Instructions from './Instructions'
import NameAndDisplayName from './NameAndDisplayName'

const SwitchLanguage = ({
    name,
    setName,
    language,
    setLanguage,
    displayName,
    instructions,
    setDisplayName,
    hideDisplayName,
    setInstractions,
    hideInstructions,
    handleDataByLanguage,
}) => {
    return (
        <FormGroup>
            <Row className="w-100">
                <Language
                    sm={12}
                    md={4}
                    language={language}
                    setLanguage={setLanguage}
                />
            </Row>
            <NameAndDisplayName
                setName={setName}
                required
                name={name}
                hideDisplayName={hideDisplayName}
                className={'px-3 flex-grow-1'}
                displayName={displayName}
                setDisplayName={setDisplayName}
            />

            {!hideInstructions && (
                <Instructions
                    instructions={instructions}
                    setInstractions={setInstractions}
                />
            )}
            <Button
                onClick={handleDataByLanguage}
                className="btn-fill d-block m-auto"
                color="primary"
                type="button">
                SAVE
            </Button>
        </FormGroup>
    )
}

export default SwitchLanguage

/* eslint-disable react-hooks/exhaustive-deps */
import {styled} from 'common'
import {Button} from 'components/general/Control'
import {skipToken} from '@reduxjs/toolkit/dist/query'

import WorkoutsDragAndDrop from 'components/general/Control/DragAndDrop/WorkoutsDragAndDrop'
import AppInput from 'components/general/Control/Input'
import {Spinner} from 'components/general/Display'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {getUrlParamsObject, useHistory, useDispatch} from 'services/hooks'
import {
    formatDataToDragAndDropFullObject,
    getNextQuarter,
    getQuarters,
    getSuccessSaveMessageData,
    showAlertMessage,
} from 'services/utils'
import {
    useGetRoutineDetailQuarterQuery,
    useGetPaginationWorkoutQuery,
    useSaveRoutineMutation,
    useVerifyRoutineNameMutation,
} from 'store/store'
import CompositeInput from 'components/general/Control/CompositeInput'

function RoutineDetail({onError}) {
    const dispatch = useDispatch()
    const params = useParams()

    const urlParams = getUrlParamsObject()
    const location = useLocation()
    const isNewRoutine = location.pathname.includes('new')

    const history = useHistory()

    const [selectedWorkouts, setSelectedWorkouts] = useState([])
    const [showSelectedWorkoutsError, setShowSelectedWorkoutsError] =
        useState(false)

    const [updatedRoutine, setUpdatedRoutine] = useState({})

    const [verifyRoutineName, {isLoading: isVerifyingRoutineName}] =
        useVerifyRoutineNameMutation(
            !updatedRoutine.name
                ? skipToken
                : {
                      name: updatedRoutine.name,
                      start_date: updatedRoutine.start_date,
                      device_type: updatedRoutine.device_type,
                  }
        )

    const [queryPaginateWo, setQueryPaginateWo] = useState({
        active: true,
        newVersion: true,
        keywords: '',
        device_type: params.device_type,
    })
    const {data: paginateWo} = useGetPaginationWorkoutQuery(queryPaginateWo)

    const {data: routineDetail, isLoading: isRoutineDetailLoading} =
        useGetRoutineDetailQuarterQuery(
            isNewRoutine
                ? skipToken
                : {
                      device_type: params.device_type,
                      start_of_quarter: urlParams.start_of_quarter,
                      end_of_quarter: urlParams.end_of_quarter,
                  }
        )

    const [saveRoutine, {isSuccess: isSuccessSaving, isLoading: isSaving}] =
        useSaveRoutineMutation()

    const extractAfterDash = (input) => {
        const lastIndexOfDash = input.lastIndexOf('-')

        if (lastIndexOfDash !== -1) {
            const beforeLastDash = input.substring(0, lastIndexOfDash)
            const afterLastDash = input.substring(lastIndexOfDash)

            return {beforeLastDash, afterLastDash}
        }

        return null
    }

    useEffect(() => {
        if (routineDetail) {
            const nameSplit = extractAfterDash(routineDetail.name)
            setUpdatedRoutine({
                name: `${nameSplit.beforeLastDash}`,
                quarter: `${nameSplit.afterLastDash}`,
                description: routineDetail.description,
                start_date: routineDetail.start_of_quarter,
                end_date: routineDetail.end_of_quarter,
                device_type: routineDetail.device_type,
            })
            const workoutsList = routineDetail.workouts

            const mappedDetailWorkouts = formatDataToDragAndDropFullObject(
                workoutsList
            ).map((w) => {
                if (w.id === routineDetail.current_workout_id) {
                    return {...w, name: `${w.name} [ Current Week ]`}
                }

                return w
            })

            setSelectedWorkouts(mappedDetailWorkouts)
        }
    }, [routineDetail])

    useEffect(() => {
        if (isNewRoutine) {
            if (urlParams.end_of_last_quarter) {
                const nextQuarter = getNextQuarter(
                    urlParams.end_of_last_quarter
                )
                setUpdatedRoutine({
                    ...routineDetail,
                    name: `${urlParams.device_label} - Daily Habit Routine`,
                    quarter: `- Q${nextQuarter.quarter} ${
                        nextQuarter.endDate.split('-')[0]
                    }`,
                    start_date: nextQuarter.startDate,
                    end_date: nextQuarter.endDate,
                    device_type: params.device_type,
                })
            } else {
                const nextQuarter = getQuarters()[0]
                setUpdatedRoutine({
                    ...updatedRoutine,
                    name: `${urlParams.device_label} - Daily Habit Routine`,
                    quarter: `- Q${nextQuarter.quarter} ${
                        nextQuarter.endDate.split('-')[0]
                    }`,
                    start_date: nextQuarter.startDate,
                    end_date: nextQuarter.endDate,
                    device_type: params.device_type,
                })
            }
        }
    }, [
        isNewRoutine,
        params.device_type,
        routineDetail,
        urlParams.end_of_last_quarter,
    ])

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    useEffect(() => {
        if (isSuccessSaving) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updatedRoutine.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [isSuccessSaving, redirectToList, dispatch, updatedRoutine.name])

    const generalContentRef = useRef()

    const [showError, setShowError] = useState(false)

    const updateProgramField = useCallback(
        (field, value) => {
            setUpdatedRoutine({...updatedRoutine, [field]: value})
        },
        [setUpdatedRoutine, updatedRoutine]
    )

    const clearAlerts = useCallback(() => {
        setShowSelectedWorkoutsError(false)
    }, [setShowSelectedWorkoutsError])

    const doSaveRoutine = async () => {
        const routine = {
            ...updatedRoutine,
            name: `${updatedRoutine.name} ${updatedRoutine.quarter}`,
            workout_ids: selectedWorkouts.map((workout) => workout.id),
            catalog_label_ids: [],
            device_type: updatedRoutine.device_type,
        }
        try {
            await saveRoutine({
                routine,
            }).unwrap()
        } catch (err) {
            console.log('err while saving routine; ', err)
        }
    }

    const onSave = async () => {
        !(await isErrorFound()) && doSaveRoutine()
    }

    const showRequiredFieldsError = () => {
        onError('showError')
        setShowError(true)
        generalContentRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'start',
        })
        return true
    }

    const isErrorFound = async () => {
        clearAlerts()

        if (!updatedRoutine.name) {
            showRequiredFieldsError()
            return true
        }

        if (!selectedWorkouts.length) {
            onError('showSelectedWorkoutsError')
            setShowSelectedWorkoutsError(true)
            return true
        }

        const {data} = await verifyRoutineName({
            name: `${updatedRoutine.name} ${updatedRoutine.quarter}`,
            start_date: updatedRoutine.start_date,
            device_type: updatedRoutine.device_type,
        })

        if (!data.is_valid) {
            onError('showInvalidNameError')
            setShowError(true)
            return true
        }

        return false
    }

    const isEmpty = (object) => {
        return Object.keys(object).length === 0
    }

    const showSpinner = useMemo(
        // eslint-disable-next-line complexity
        () =>
            isSaving ||
            isSuccessSaving ||
            isVerifyingRoutineName ||
            isRoutineDetailLoading ||
            (!isNewRoutine && isEmpty(routineDetail)),
        [
            isSaving,
            isSuccessSaving,
            isVerifyingRoutineName,
            isRoutineDetailLoading,
            isNewRoutine,
            routineDetail,
        ]
    )

    if (showSpinner) {
        return (
            <SpinnerContainer className="d-flex w-100 h-100 justify-content-center align-items-center flex-grow-1">
                <Spinner label={'PREPARING DAILY HABIT ROUTINES DATA . . .'} />
            </SpinnerContainer>
        )
    }

    return (
        <div header={'Routines Details'} style={{height: '1000px'}}>
            <GeneralContentContainer ref={generalContentRef}>
                <Col
                    className="pl-0 pr-0 pr-sm-0 pr-md-3"
                    sm="12"
                    lg="6"
                    md="6">
                    <FormGroup>
                        <label>DAILY HABIT ROUTINE NAME*</label>
                        <CompositeInput
                            value={updatedRoutine.name}
                            staticValue={updatedRoutine.quarter}
                            setter={(value) =>
                                updateProgramField('name', value)
                            }
                            setShowError={setShowError}
                            showError={showError}
                        />
                    </FormGroup>
                </Col>
            </GeneralContentContainer>
            <Row className="px-3">
                <Col
                    className="pl-0 pr-0 pr-sm-0 pr-md-3"
                    sm="12"
                    lg="6"
                    md="6">
                    <FormGroup>
                        <label>START DATE</label>
                        <AppInput
                            disable
                            name="start_date"
                            value={updatedRoutine.start_date}
                            setter={(value) =>
                                updateProgramField('start_date', value)
                            }
                            showError={showError}
                        />
                    </FormGroup>
                </Col>
                <Col className="px-0" sm="12" lg="6" md="6">
                    <FormGroup>
                        <label>END DATE</label>
                        <AppInput
                            disable
                            name="end_date"
                            value={updatedRoutine.end_date}
                            setter={(value) =>
                                updateProgramField('end_date', value)
                            }
                            showError={showError}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup className="col-sm-12 col-md-6 col-lg-6 px-0 h-auto pr-md-3 mb-0">
                <Label>DESCRIPTION</Label>
                <StyledInput
                    id="description"
                    className="px-2"
                    type="textarea"
                    placeholder="Add Description"
                    value={updatedRoutine.description}
                    onChange={({target}) =>
                        updateProgramField('description', target.value)
                    }
                />
            </FormGroup>

            <WorkoutsDragAndDrop
                showSelectedWorkoutsError={showSelectedWorkoutsError}
                setSelectedWorkouts={setSelectedWorkouts}
                selectedWorkouts={selectedWorkouts}
                workouts={paginateWo?.data}
                setQueryPaginateWo={setQueryPaginateWo}
            />

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="button"
                    onClick={onSave}>
                    {isNewRoutine ? 'CREATE' : 'MODIFY'} DAILY HABIT ROUTINE
                </Button>
            </Row>
        </div>
    )
}

export default RoutineDetail
const GeneralContentContainer = styled.div``
const SpinnerContainer = styled.div``
const StyledInput = styled(Input)`
    resize: auto;
    max-height: 200;
    min-height: 50;
    margin-bottom: 0px !important;
`

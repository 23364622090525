import React, {useMemo} from 'react'
import {LANGUAGES} from 'constant/languages'
import {FormGroup, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {useGetConfigByIdQuery} from '../../../store/slices/config.js'

const Language = ({
    language,
    setLanguage,
    className,
    sm = 12,
    md = 6,
    lg = 6,
    hide,
    flexEnd,
    disabled = false,
}) => {
    const {data: configData} = useGetConfigByIdQuery({id: 'language'})

    const languageOptions = useMemo(() => {
        if (Object.keys(configData?.data || {}).length) {
            const lang = configData?.data
            return Object.keys(lang).map((key) => ({
                value: key,
                label: lang[key],
            }))
        }

        return LANGUAGES
    }, [configData])

    if (hide) {
        return null
    }

    return (
        <Col
            className={`${className} "pl-0 pr-0 pr-sm-0 pr-md-3"`}
            sm={sm}
            lg={lg}
            md={md}>
            <FormGroup
                className={`d-flex px-0  ${flexEnd && 'justify-content-end'}`}>
                <SelectInput
                    className={'px-0 w-100'}
                    name={'language'}
                    label={'language'}
                    options={languageOptions}
                    selected={language}
                    onChange={setLanguage}
                    placeholder={'select language'}
                    disabled={disabled}
                />
            </FormGroup>
        </Col>
    )
}

export default Language

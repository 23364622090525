import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from 'store/common'

const transformResponseFromData = (response) => response.data
const transformResponseFromMessage = (response) => response.message

const provideTags = (tagName, data) =>
    data
        ? [
              ...data.map(({id}) => ({type: tagName, id})),
              {type: tagName, id: 'LIST'},
          ]
        : [{type: tagName, id: 'LIST'}]

const provideTag = (tagName, data, tagField) =>
    data ? {type: tagName, id: data[tagField]} : {}

const programApi = createApi({
    baseQuery: axiosBaseQuery(),

    reducerPath: 'program',
    tagTypes: ['Program', 'ProgramPagination'],
    endpoints: (build) => ({
        getPrograms: build.query({
            query: () => ({
                url: '/admin/program',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Program', data?.data),
        }),
        getProgramPagination: build.query({
            query: (params) => ({
                url: 'admin/program/pagination',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                provideTags('ProgramPagination', data?.data),
        }),
        getProgram: build.query({
            query: ({id, language = 'en'}) => ({
                url: `/admin/program/${id}/${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Program', data, 'id')],
        }),

        saveProgram: build.mutation({
            query: ({program, id}) => ({
                url: id ? `/admin/program/${id}` : '/admin/program/',
                method: id ? 'PUT' : 'POST',
                body: program,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Program', data, 'id')],
            invalidatesTags: (result, error, data) => [
                {type: 'Program', id: data?.id},
                {type: 'Program', id: 'LIST'},
                {type: 'ProgramPagination', id: 'LIST'},
            ],
        }),
        saveProgramTranslation: build.mutation({
            query: (body) => ({
                url: '/admin/translation/',
                method: 'POST',
                body,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Program', data, 'id')],
            invalidatesTags: (result, error, data) => [
                {type: 'Program', id: data?.id},
                {type: 'ProgramPagination', id: 'LIST'},
            ],
        }),
        getProgramsSpreadsheet: build.query({
            query: (id) => {
                return {
                    url: `/admin/program/workout/csv`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromMessage,
            providesTags: (data) => provideTags('Csv', data?.data),
        }),
        deleteProgram: build.mutation({
            query: (id) => {
                return {
                    url: `/admin/program/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result, error, data) => [
                {type: 'Program', id: data?.id},
                {type: 'ProgramPagination', id: 'LIST'},
            ],
        }),
    }),
})

export default programApi

export const {
    useGetProgramsQuery,
    useGetProgramQuery,
    useSaveProgramMutation,
    useGetProgramsSpreadsheetQuery,
    useSaveProgramTranslationMutation,
    useDeleteProgramMutation,
    useGetProgramPaginationQuery,
} = programApi

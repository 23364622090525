import React, {useEffect, useRef, useState} from 'react'
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel'
import {FormGroup, Input, Label} from 'reactstrap'
import styled from 'styled-components'
import SweetAlert from 'components/general/Display/SweetAlert.js'

const MultiCheckboxSelection = ({
    name,
    label,
    required,
    showError,
    onChange,
    options = [],
    selected = [],
    selectionLimit,
    disabled = false,
}) => {
    const [showAlert, setShowAlert] = useState(false)

    const alertRef = useRef()

    useEffect(() => {
        showAlert && alertRef.current()
    }, [showAlert])

    const showErrorClass = showError && !selected?.length && 'border-danger '

    const isOptionChecked = (option) => selected.includes(option)

    const handleChange = (value) => {
        const isChecked = isOptionChecked(value)
        const willExceedFromLimitNumber =
            selectionLimit && selected.length === selectionLimit && !isChecked

        if (willExceedFromLimitNumber) {
            return setShowAlert(true)
        }
        const newArray = isChecked
            ? selected.filter((item) => item !== value)
            : [...selected, value]
        onChange(newArray)
    }
    const displayName = required ? label + ' *' : label
    return (
        <Container>
            {label && (
                <StyledLabel className="form-check-label">
                    {displayName}
                </StyledLabel>
            )}
            <FormGroup
                className={`form-check form-control  mt-0 px-2 h-auto ${showErrorClass}`}>
                {options.map((option, i) => (
                    <FormCheckLabel className="w-50 my-2" key={option + i}>
                        <Input
                            className="custom-control-input form-check-input mr-2 "
                            type="checkbox"
                            name={name}
                            checked={isOptionChecked(option.value)}
                            onChange={(ev) => handleChange(option.value)}
                            disabled={disabled}
                        />
                        <span className="form-check-sign">{option.label}</span>
                    </FormCheckLabel>
                ))}
            </FormGroup>
            <SweetAlert
                ref={alertRef}
                title={'selection Limit'}
                mainText={`limit number to select is ${selectionLimit}`}
                action=""
                deactivate={() => {}}
                hideAlertOnConfirm={() => {
                    setShowAlert(null)
                }}
                confirmBtnText="Got it"
                hideCancel
            />
        </Container>
    )
}

export default MultiCheckboxSelection

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledLabel = styled(Label)`
    text-transform: uppercase;
`

import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from 'store/common'

const transformResponseFromData = (response) => response.data

// const transformResponseFromMessage = (response) => response.message

const provideTags = (tagName, data) =>
    data
        ? [
              ...data.map(({id}) => ({type: tagName, id})),
              {type: tagName, id: 'LIST'},
          ]
        : [{type: tagName, id: 'LIST'}]

const provideTag = (tagName, data, tagField) =>
    data ? {type: tagName, id: data[tagField]} : {}

const configApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'config',
    tagTypes: ['ConfigPagination', 'Config'],
    endpoints: (build) => ({
        getPaginationConfig: build.query({
            query: () => ({
                url: 'admin/config',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('ConfigPagination', data?.data),
        }),
        getConfigById: build.query({
            query: ({id, language = 'en'}) => ({
                url: `admin/config/${id}?language=${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Config', data, '_id')],
        }),
        saveConfig: build.mutation({
            query: ({config, isNewConfig}) => ({
                url: 'admin/config',
                method: isNewConfig ? 'POST' : 'PUT',
                body: config,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Config', data, '_id')],
            invalidatesTags: (_, __, data) => [
                {type: 'ConfigPagination', id: 'LIST'},
                {type: 'Config', id: data?._id},
            ],
            refetchOnMountOrArgChange: false,
        }),
    }),
})

export default configApi

export const {
    useGetPaginationConfigQuery,
    useGetConfigByIdQuery,
    useSaveConfigMutation,
} = configApi

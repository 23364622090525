import {createApi} from '@reduxjs/toolkit/query/react'
import {
    axiosBaseQuery,
    transformResponseFromData,
    provideTags,
    provideTag,
} from 'store/common'

const accountApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'account',
    tagTypes: ['account'],
    endpoints: (build) => ({
        getAccounts: build.query({
            query: () => ({
                url: 'accounts',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('account', data?.data),
        }),
        getAccount: build.query({
            query: (id) => ({
                url: `accounts/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('account', data, 'id')],
        }),
        saveAccount: build.mutation({
            query: (data) => ({
                url: 'accounts',
                method: 'POST',
                body: data,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('account', data, 'id')],
            invalidatesTags: (result, error, data) =>
                result && [{type: 'account', id: data?.id}],
        }),
    }),
})

export default accountApi

export const {
    useLazyGetAccountsQuery,
    useGetAccountQuery,
    useSaveAccountMutation,
} = accountApi

import {Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {useGetConfigByIdQuery} from 'store/slices/config'

const MuscleGroupSelection = ({muscleGroup, setMuscleGroup, showError}) => {
    const {data: configData} = useGetConfigByIdQuery({id: 'global_enum'})

    return (
        <Col md={6} className="px-0">
            <SelectInput
                required
                showError={showError}
                selected={muscleGroup}
                onChange={setMuscleGroup}
                options={configData?.data?.muscle_group}
                name={'primary_muscle_group'}
                label={'primary muscle group'}
            />
        </Col>
    )
}

export default MuscleGroupSelection

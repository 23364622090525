import {Col, FormGroup, Label, Row} from 'reactstrap'
import {ChromePicker} from 'react-color'
import {
    useCallback,
    useDispatch,
    useEffect,
    useHistory,
    useParams,
    useState,
} from 'services/hooks'
import {useGetAccountQuery, useSaveAccountMutation} from 'store/store'
import AppInput from 'components/general/Control/Input'
import {Button} from 'components/general/Control'
import {getSuccessSaveMessageData, showAlertMessage} from 'services/utils.js'

const AccountDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const [color, setColor] = useState('rgba(255,255,255,100)')

    const {data: accountData} = useGetAccountQuery(params.id)

    const [doSaveAccount, {isSuccess}] = useSaveAccountMutation()
    const [updatedAccount, setUpdatedAccount] = useState({})

    const updateAccountTheme = (field, value) => {
        const theme = {...(updatedAccount?.theme || {})}

        Object.assign(theme, {[field]: value})
        setUpdatedAccount((prevState) => ({...prevState, theme}))
    }

    const updateLinear = (field, idx, value) => {
        const linear = [...(updatedAccount?.theme?.[field] || [])]
        linear[idx] = value
        updateAccountTheme(field, linear)
    }

    useEffect(() => {
        if (accountData) {
            setUpdatedAccount(accountData)
        }
    }, [accountData])

    const handleSaveAccount = (e) => {
        e?.preventDefault()

        doSaveAccount(updatedAccount)
            .unwrap()
            .catch((r) => {
                onError(r?.data?.message)
            })
    }

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updatedAccount?.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updatedAccount])

    return (
        <div>
            <p>Account Theme Config</p>
            <Row>
                <Col sm="12" className="mb-4">
                    <ChromePicker
                        defaultView={'rgb'}
                        color={color}
                        onChange={(v) => {
                            const {r, g, b, a} = v.rgb
                            const newColor = `rgba(${r},${g},${b},${a})`
                            setColor(newColor)
                        }}
                    />
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Primary Color</Label>
                        <AppInput
                            name="primaryColor"
                            value={updatedAccount?.theme?.primaryColor}
                            setter={(v) =>
                                updateAccountTheme('primaryColor', v)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Secondary Color</Label>
                        <AppInput
                            name="secondaryColor"
                            value={updatedAccount?.theme?.secondaryColor}
                            setter={(v) =>
                                updateAccountTheme('secondaryColor', v)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Tertiary Color</Label>
                        <AppInput
                            name="tertiaryColor"
                            value={updatedAccount?.theme?.tertiaryColor}
                            setter={(v) =>
                                updateAccountTheme('tertiaryColor', v)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Forth Color</Label>
                        <AppInput
                            name="forthColor"
                            value={updatedAccount?.theme?.forthColor}
                            setter={(v) => updateAccountTheme('forthColor', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="6">
                    <FormGroup>
                        <Label>Linear Primary 1</Label>
                        <AppInput
                            name="linearPrimary1"
                            value={
                                updatedAccount?.theme?.linearPrimary?.[0] || ''
                            }
                            setter={(v) => {
                                updateLinear('linearPrimary', 0, v)
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Linear Primary 2</Label>
                        <AppInput
                            name="linearPrimary2"
                            value={
                                updatedAccount?.theme?.linearPrimary[1] || ''
                            }
                            setter={(v) => {
                                updateLinear('linearPrimary', 1, v)
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm="6">
                    <FormGroup>
                        <Label>Linear Secondary 1</Label>
                        <AppInput
                            name="linearSecondary1"
                            value={
                                updatedAccount?.theme?.linearSecondary?.[0] ||
                                ''
                            }
                            setter={(v) => {
                                updateLinear('linearSecondary', 0, v)
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Linear Secondary 2</Label>
                        <AppInput
                            name="linearSecondary2"
                            value={
                                updatedAccount?.theme?.linearSecondary[1] || ''
                            }
                            setter={(v) => {
                                updateLinear('linearSecondary', 1, v)
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm="6">
                    <FormGroup>
                        <Label>Loader Image URL</Label>
                        <AppInput
                            name="loaderImageUrl"
                            value={updatedAccount?.theme?.loaderImageUrl}
                            setter={(v) =>
                                updateAccountTheme('loaderImageUrl', v)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Logo Image URL</Label>
                        <AppInput
                            name="logoImageUrl"
                            value={updatedAccount?.theme?.logoImageUrl}
                            setter={(v) =>
                                updateAccountTheme('logoImageUrl', v)
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="submit"
                    onClick={handleSaveAccount}>
                    Save Account
                </Button>
            </Row>
        </div>
    )
}
export default AccountDetail

import {React, styled} from 'common'
import {Texts} from 'appearance'
import Header from './Header'
import Sidebar from './Sidebar'
import {SweetMessage} from 'components/general/Display'

import {
    useWindowSize,
    useState,
    useEffect,
    Redirect,
    useSelector,
} from 'services/hooks'
import routes from 'navigation/routes'
import {useAuthState} from 'store/store'

const Page = ({preventBars = false, title, children, hideSidbar}) => {
    const [sidebarOpened, setSidebarOpened] = useState(!hideSidbar)
    const messageRef = React.createRef()
    const windowSize = useWindowSize()
    const {authenticated} = useSelector(useAuthState)

    const toggleSidebar = () => {
        setSidebarOpened(!sidebarOpened)
    }

    const getActiveRoute = (routes) => {
        if (window.location.pathname === '/login') return

        const active = routes.find((route) => {
            const path = route.layout + route.path
            return route.specific
                ? window.location.pathname.includes(path)
                : path === window.location.pathname
        })
        return active !== -1 ? active?.title : 'NOT FOUND'
    }

    useEffect(() => {
        messageRef?.current()
    }, [messageRef])
    useEffect(() => {
        if (window.location.pathname !== '/login') {
            if (!authenticated) {
                return <Redirect to="/login" />
            }
        }
    }, [authenticated])

    return (
        <Container
            h={windowSize.height}
            className={sidebarOpened ? 'nav-open sidebar-mini' : ''}>
            {!preventBars && <Sidebar routes={routes} />}

            <InnerContainer className="main-panel" data={'primary'}>
                {!preventBars && (
                    <PageHeader
                        toggleSidebar={toggleSidebar}
                        sidebarOpened={sidebarOpened}
                        activeRoute={getActiveRoute(routes)}
                    />
                )}
                <Content
                    sidebarOpened={sidebarOpened}
                    onKeyDown={(e) => {
                        if (
                            e.key === 'Enter' &&
                            e?.target?.localName !== 'textarea'
                        ) {
                            e.preventDefault() // Prevent form submission
                            e.target.blur() // Unfocused the input field
                        }
                    }}>
                    {title ? <Texts.Title>{title}</Texts.Title> : null}
                    {children}
                </Content>
            </InnerContainer>
            <SweetMessage ref={messageRef} />
        </Container>
    )
}

export default Page

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: ${(p) => p.h}px;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const PageHeader = styled(Header)`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
`

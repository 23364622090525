import {Col, Row, FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import React from 'react'
import {useGetConfigByIdQuery} from 'store/slices/config'

export const GENDERS = [
    {
        value: 'female',
        label: 'Famale',
    },
    {
        value: 'male',
        label: 'Male',
    },
]

const TrainerGenderSelection = ({
    trainerGender,
    setTrainerGender,
    showError,
}) => {
    const {data: configData} = useGetConfigByIdQuery({id: 'global_enum'})

    return (
        <Row form>
            <Col md="4">
                <FormGroup>
                    <RadioButtonsInput
                        required
                        options={configData?.data?.trainer_gender}
                        showError={showError}
                        name={'trainer_gender'}
                        selected={trainerGender}
                        label={'Trainer Gender'}
                        onChange={setTrainerGender}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}
export default TrainerGenderSelection

import {React, styled} from 'common'
import {Colors} from 'appearance'
import {
    createFormData,
    getIsActiveState,
    getIsEmptyMandatoryField,
    getSuccessSaveMessageData,
    showAlertMessage,
    getEntityDeletionAlertProps,
} from 'services/utils'
import {Collapse} from 'components/general/Control'
import {
    caloriesMETOptions,
    mandatoryDraftFields,
    mandatoryFields,
} from 'constant/exercise'

import {
    BarContent,
    RopeContent,
    Gear1Content,
    GearGoContent,
    GearXContent,
    MotionAnalysisContent,
    NoBandsContent,
} from 'components/Exercise/Details'
import {
    ImageUpload,
    ControlButtons,
    ActivationInput,
    VimeoInformation,
} from 'components/Details'
import GeneralContent from 'components/Exercise/Details/GeneralContent'
import Spinner from 'components/general/Display/Spinner'
import {
    useCallback,
    useEffect,
    useMemo,
    useDispatch,
    useHistory,
    useState,
} from 'services/hooks'
import {useGetExercisesQuery} from 'store/store'
import {getIsPerformanceMode} from 'services/exercise'
import Language from 'components/general/Display/Language'
import {SweetAlert} from 'components/general/Display'
import ConfirmAlert from 'components/general/Display/ConfirmAlert'

const getIsMissingDraftRequiredFields = (updatedExercise) => {
    return !getIsEmptyMandatoryField(mandatoryDraftFields, {
        name: updatedExercise?.name,
        display_name: updatedExercise?.display_name,
    })
}

const dataByEquipment = {
    bar: BarContent,
    normal: Gear1Content,
    gearGo: GearGoContent,
    gearX: GearXContent,
    rtf: MotionAnalysisContent,
    rope: RopeContent,
    no_bands: NoBandsContent,
    spiderB: GearGoContent,
    spiderS: GearGoContent,
}

const ExerciseDetails = ({
    isNewExercise,
    saveOptions,
    exercise,
    saveExercise,
    language,
    setLanguage,
    showError,
    showDraftError,
    setShowDraftError,
    setShowError,
    showSpinner,
    imageFile,
    setImageFile,
    updatedExercise,
    setUpdatedExercise,
    showNameError,
    setShowNameError,
    onError,
    deleteExercise,
    isSuccessDeleting,
}) => {
    const {isSuccessSaving} = saveOptions
    const history = useHistory()
    const dispatch = useDispatch()
    const {data: exercises} = useGetExercisesQuery()
    const {
        name,
        active,
        vimeo_id,
        mute_video,
        exercise_type,
        duration,
        video_url,
    } = updatedExercise || {}
    const [showDeletionPopUp, setShowDeletionPopUp] = useState(false)
    const [showConfirmAlert, setShowConfrimAlert] = useState(false)

    const alertRef = React.createRef()

    const updateExerciseField = (field, value) => {
        setUpdatedExercise({...updatedExercise, [field]: value})
    }

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    useEffect(() => {
        const shouldShowSuccess = isSuccessSaving || isSuccessDeleting
        if (shouldShowSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(name)

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccessSaving, name, redirectToList, isSuccessDeleting])

    useEffect(() => {
        name?.length && setShowNameError(false)
    }, [name, setShowNameError])

    const isPerformanceMode = getIsPerformanceMode(updatedExercise)

    const checkRequiredFields = () =>
        getIsEmptyMandatoryField(
            mandatoryFields,
            {...updatedExercise, image: imageFile},
            exercise_type
        )

    const prepareNewExercise = () => {
        const newExercise = {
            ...updatedExercise,
            name: updatedExercise.name.trim(),
            difficulty: Number(updatedExercise?.difficulty) || 0,
            vimeo_id: updatedExercise.vimeo_id || '0',
        }

        addExerciseImage(newExercise)
        addExerciseMetCategory(newExercise)
        return newExercise
    }

    const addExerciseImage = (newExercise) => {
        if (imageFile.file) {
            newExercise.img = imageFile.file
            delete newExercise.image
        }
    }

    const addExerciseMetCategory = (newExercise) => {
        const {met} = newExercise || {}
        if (met) {
            newExercise.met = Number(met)
            newExercise.met_category = caloriesMETOptions.find(
                (item) => item.value === Number(met)
            )?.label
        }
    }

    const handleDraftError = () => {
        onError('showError')
        setShowDraftError(true)
        return false
    }

    const checkIsNameUniqe = useCallback(() => {
        const existingName = exercises?.find(
            (ex) =>
                ex.name.toLowerCase().trim() === name.toLowerCase().trim() &&
                (isNewExercise ? true : ex.id !== exercise?.id)
        )
        return !existingName
    }, [exercises, name, isNewExercise, exercise])

    const handleNameCheckBeforeSave = () => {
        const isNmaeUniqe = checkIsNameUniqe()
        if (!isNmaeUniqe) {
            onError('showNameError')
            setShowNameError(true)
            return false
        }
        return true
    }

    const checkIsAllowdToSave = (isActive) => {
        if (isActive && !checkRequiredFields()) {
            onError('showError')
            setShowError(true)
            return
        }

        if (!isActive && getIsMissingDraftRequiredFields(updatedExercise)) {
            return handleDraftError()
        }
        return handleNameCheckBeforeSave()
    }

    const cleanErrors = () => {
        setShowNameError(false)
        setShowError(false)
        setShowDraftError(false)
    }

    const onSaveExercise = async (ev, toggleActive = false) => {
        ev && ev.preventDefault()
        cleanErrors()
        const isActiveState = getIsActiveState(toggleActive, updatedExercise)

        if (!checkIsAllowdToSave(isActiveState)) {
            return
        }

        const newExercise = prepareNewExercise()

        if (toggleActive) {
            newExercise.active = !newExercise.active
        }

        doSaveExercise(newExercise)
    }

    const onCheckBeforUpgradeVersion = async (ev) => {
        ev && ev.preventDefault()

        cleanErrors()

        if (!checkIsAllowdToSave(true)) {
            return
        }

        setShowConfrimAlert(true)
    }

    const onUpgradeVersion = () => {
        const newExercise = prepareNewExercise()
        newExercise.version = 2
        newExercise.active = false

        doSaveExercise(newExercise)
    }

    const doSaveExercise = async (newExercise) => {
        const exerciseFormData = createFormData(newExercise)
        try {
            await saveExercise({
                exercise: exerciseFormData,
                id: newExercise?.id,
            }).unwrap()
        } catch (err) {
            const {message} = err.data
            showAlertMessage(message, 'alert-danger', dispatch)
            redirectToList(false)
        }
    }

    const onActivateExercise = () => onSaveExercise(null, true)

    const EquipmentDetailsComponent = dataByEquipment[exercise_type]
    const hideControlButtons = useMemo(
        () => !isNewExercise && exercise?.version < 2,
        [exercise?.version, isNewExercise]
    )

    useEffect(() => {
        showDeletionPopUp && alertRef?.current()
    }, [showDeletionPopUp, alertRef])

    if (showSpinner) {
        return (
            <SpinnerContainer className="d-flex w-100 h-100 justify-content-center align-items-center flex-grow-1">
                <Spinner label={'PREPARING EXERCISE DETAILS . . .'} />
            </SpinnerContainer>
        )
    }

    const deletionAction = (id) => {
        deleteExercise(id)
    }
    return (
        <div>
            <Language
                className={'px-0'}
                language={language}
                setLanguage={setLanguage}
                hide={isNewExercise}
            />
            <GeneralContent
                showError={showError}
                showNameError={showNameError}
                showDraftError={showDraftError}
                updatedExercise={updatedExercise}
                updateExerciseField={updateExerciseField}
            />
            <EquipmentDetails isOpen={Boolean(EquipmentDetailsComponent)}>
                {EquipmentDetailsComponent && (
                    <EquipmentDetailsComponent
                        updateExerciseField={updateExerciseField}
                        showError={showError}
                        updatedExercise={updatedExercise}
                        isPerformanceMode={isPerformanceMode}
                    />
                )}
            </EquipmentDetails>
            <ImageUploadContainer>
                <ImageUpload
                    imageFile={imageFile}
                    setImage={setImageFile}
                    showError={showError}
                />
            </ImageUploadContainer>

            <VimeoInformation
                duration={duration}
                setDuration={(value) => updateExerciseField('duration', value)}
                vimeoId={vimeo_id}
                setVimeoId={(value) => updateExerciseField('vimeo_id', value)}
                mutedVideo={Boolean(mute_video)}
                setMuteVideo={(value) =>
                    updateExerciseField('mute_video', value)
                }
                showError={showError}
                setVideoUrl={(value) => updateExerciseField('video_url', value)}
                video_url={video_url}
            />
            {!exercise?.id && (
                <ActivationInput
                    active={Boolean(active)}
                    setactive={(value) => updateExerciseField('active', value)}
                />
            )}
            <ControlButtons
                hideControls={hideControlButtons}
                onSave={onSaveExercise}
                isActive={exercise?.active}
                hideActivationButton={!exercise?.id}
                onActivate={onActivateExercise}
                onUpgradeVersion={onCheckBeforUpgradeVersion}
                onDelete={() => setShowDeletionPopUp(true)}
                topic={'exercise'}
            />
            <SweetAlert
                ref={alertRef}
                hideAlertExtraFunction={() => setShowDeletionPopUp(false)}
                id={exercise?.id}
                {...getEntityDeletionAlertProps(
                    exercise,
                    'Exercise',
                    deletionAction
                )}
            />
            <ConfirmAlert
                showAlert={showConfirmAlert}
                title="Confirm Upgrade"
                mainText="Are you sure you want to upgrade the exercise to version 2? This change cannot be reversed"
                confirmText="Upgrade"
                cancelText="Cancel"
                onConfirm={() => onUpgradeVersion()}
                onCancel={() => setShowConfrimAlert(false)}
            />
        </div>
    )
}
export default ExerciseDetails

const SpinnerContainer = styled.div``

const ImageUploadContainer = styled.div`
    min-height: 350px;
    display: flex;
    justify-content: center;
`

const EquipmentDetails = styled(Collapse)`
    background-color: ${Colors.INNER_CARD_BACKGROUND};
    padding: 10px;
    border-radius: 5px;
`

import SelectInput from 'components/general/Control/SelectInput'
import {useGetConfigByIdQuery} from 'store/slices/config'

const BodyRegionsFocus = ({region, setRegion, showError}) => {
    const {data: configData} = useGetConfigByIdQuery({id: 'global_enum'})

    return (
        <SelectInput
            required
            showError={showError}
            selected={region}
            onChange={setRegion}
            options={configData?.data?.body_region_focus}
            name={'body_region_focus'}
            label={'body region focus'}
        />
    )
}

export default BodyRegionsFocus

import React, {useEffect, useMemo, useState} from 'react'
import {Page} from 'components/general/Page'
import PageContent from 'components/general/Page/PageContent'
import {TableWrapper} from 'components/general/Display/Table'
import {
    useLazyGetUserReportPDFQuery,
    useLazyGetAccountsQuery,
} from 'store/store'
import hygearLogo from 'assets/img/hygear-logo.svg'
import downloadLogo from 'assets/img/download.svg'
import {useHistory} from 'services/hooks'
import moment from 'moment'
import Tooltip from 'components/reportWeekly/Tooltip'

const AccountList = () => {
    const [dataSelect, setDataSelect] = useState({_id: ''})
    const startDate = moment()
        .subtract(1, 'week')
        .startOf('week')
        .format('DD/MM/YYYY')
    const endDate = moment(startDate, 'DD/MM/YYYY')
        .endOf('week')
        .format('DD/MM/YYYY')
    const history = useHistory()
    const [getAccount, account] = useLazyGetAccountsQuery(
        {},
        {
            refetchOnMountOrArgChange: true,
        }
    )

    const [getReportPDF, reportPDF] = useLazyGetUserReportPDFQuery()
    useEffect(() => {
        if (
            !reportPDF.isLoading &&
            reportPDF.isSuccess &&
            dataSelect._id !== ''
        ) {
            history.push({
                pathname: '/admin/report-weekly/' + dataSelect?._id,
                state: {
                    ...reportPDF.data,
                    ...dataSelect,
                },
            })
        }
    }, [dataSelect, history, reportPDF])

    useEffect(() => {
        getAccount()
    }, [getAccount])

    const data = useMemo(
        () =>
            account.data && account.data.data && account.data.data.length > 0
                ? account.data.data.map((prop, key) => {
                      return {
                          pathname: `/admin/account/${prop._id}`,
                          id: key,
                          account: prop.name,
                          _id: prop._id,
                          logo: prop.image ? (
                              <img
                                  width={92}
                                  height={64}
                                  src={prop.image}
                                  alt="logo"
                              />
                          ) : (
                              <img
                                  width={112}
                                  height={28}
                                  src={hygearLogo}
                                  alt="logo"
                              />
                          ),
                          person: prop.contact_name ? prop.contact_name : '-',
                          email: prop.contact_email ? prop.contact_email : '-',
                          numberOfUsers: prop.numberOfUsers
                              ? prop.numberOfUsers
                              : 0,
                          action: (
                              <Tooltip
                                  content={`Download overview weekly report ${startDate} - ${endDate}`}>
                                  <div
                                      className="pe-auto p-2"
                                      onClick={() => {
                                          setDataSelect(prop)
                                          getReportPDF(prop._id)
                                      }}>
                                      <img
                                          width={15}
                                          height={15}
                                          src={downloadLogo}
                                          alt="logo"
                                      />
                                  </div>
                              </Tooltip>
                          ),
                      }
                  })
                : [],
        [account.data, endDate, getReportPDF, startDate]
    )

    return (
        <Page hideSidbar={false}>
            <PageContent>
                <TableWrapper
                    title="Accounts List"
                    columns={columns}
                    data={data}
                    isError={account?.isError || reportPDF?.isError}
                    isFetching={account?.isLoading || reportPDF?.isLoading}
                    isRowHover={false}
                />
            </PageContent>
        </Page>
    )
}

const columns = [
    {
        Header: '',
        accessor: 'logo',
    },
    {
        Header: 'ACCOUNT',
        accessor: 'account',
    },
    {
        Header: 'NUMBER OF USERS',
        accessor: 'numberOfUsers',
    },
    {
        Header: 'CONTACT PERSON',
        accessor: 'person',
    },
    {
        Header: 'CONTACT EMAIL',
        accessor: 'email',
    },

    {
        Header: 'Action',
        accessor: 'action',
        maxWidth: 70,
    },
]

export default AccountList

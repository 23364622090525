import MultiCheckboxSelection from 'components/general/Control/MultiCheckboxSelection'
import {Col} from 'reactstrap'
import {useGetConfigByIdQuery} from 'store/slices/config'

const OverallTrainingGoal = ({required, showError, selected, updateField}) => {
    const {data: configData} = useGetConfigByIdQuery({id: 'global_enum'})

    return (
        <Col sm="12" lg="6" md="6" className="pl-0 pr-0 pr-sm-0 pr-md-3">
            <MultiCheckboxSelection
                required={required}
                showError={showError}
                label={'overall training goal'}
                options={configData?.data?.overall_goals}
                selected={selected}
                onChange={(value) => updateField('overall_goals', value)}
                selectionLimit={4}
            />
        </Col>
    )
}

export default OverallTrainingGoal

import React from 'react'
import {FormGroup, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {useGetConfigByIdQuery} from 'store/slices/config'

const ExercisePurpose = ({type, setType, showError}) => {
    const {data: configData} = useGetConfigByIdQuery({id: 'global_enum'})

    return (
        <Col className="px-0" sm="12" lg="6" md="6">
            <FormGroup>
                <SelectInput
                    required
                    selected={type}
                    label={'purpose'}
                    onChange={setType}
                    showError={showError}
                    name={'exercise_purpose'}
                    options={configData?.data?.purpose}
                />
            </FormGroup>
        </Col>
    )
}

export default ExercisePurpose

import {Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {useGetConfigByIdQuery} from 'store/slices/config'

const PostureSelection = ({posture, setPosture, showError}) => {
    const {data: configData} = useGetConfigByIdQuery({id: 'global_enum'})

    return (
        <Col md={6} className="px-0 pr-md-3">
            <SelectInput
                required
                name={'posture'}
                label={'Posture'}
                selected={posture}
                options={configData?.data?.posture}
                onChange={setPosture}
                showError={showError}
            />
        </Col>
    )
}

export default PostureSelection
